/* -------------------------------------------- Début CSS Reset ------------------------------------- */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ----------------------------------------------- Fin CSS Reset -------------------------------------------------- */

/* ----------------------------------------------- Début Polices -------------------------------------------------- */

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoItalic';
  src: local('LatoItalic'), url(./assets/fonts/Lato-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoBold';
  src: local('LatoBold'), url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoBoldItalic';
  src: local('LatoBoldItalic'), url(./assets/fonts/Lato-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoLight';
  src: local('LatoLight'), url(./assets/fonts/Lato-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'LatoLightItalic';
  src: local('LatoLightItalic'), url(./assets/fonts/Lato-LightItalic.ttf) format('truetype');
}

/* ----------------------------------------------- Fin Polices -------------------------------------------------- */
/* ----------------------------------------------- Début Variables -------------------------------------------------- */

:root {
  --TN-Blue:#0080AE;
  --navHeight: 80px;
}

/* ----------------------------------------------- Fin Variables -------------------------------------------------- */
/* ----------------------------------------------- Début CSS -------------------------------------------------- */


body {
  background-color: var(--TN-Blue);
  font-family: 'Lato';
  color: #333;

  min-height: 100vh;
}

nav {
  width: 100%;
  height: var(--navHeight);
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: var(--TN-Blue);
  z-index: 100;
  border-bottom: 2px solid var(--TN-Blue);
}

.nav-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-logo img {
  max-height: 78px;
  width: auto;
}

.titre {
  font-size: 30px;
  font-family: 'LatoBold';
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: var(--TN-Blue);
  font-size: 24px;
  padding: 0 20px 0 20px;
}

.nav-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.nav-buttons a {
  width: 80px;
  height: var(--navHeight);
  font-size: 30px;
  border-left: 2px solid var(--TN-Blue);

  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.nav-buttons i {
  margin: 0;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  padding-top: calc(var(--navHeight) + 20px);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 40px;
}

.centerDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inscription {
  width: 800px;
}

.connexion-links {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer {
  position: fixed;
  width: 100%;
  text-align: center;
  color: white;
  bottom: 10px;
  z-index: -1;
}

.calendrier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendrier {
  border-color: white !important;
}

.creneau {
  display: flex;
}

.creneau-heures {
  color: 'white';
  padding: 10px 5px 10px 5px;
  border-right: 1px solid rgba(255, 255, 255, 0.75);
}

.creneau-heures h1 {
  font-weight: 600;
  font-size: 20px;
  padding: 5px;
}

.creneau-data {
  flex-grow: 2;
  padding: 15px;
  text-align: left;
}

.creneau-data h1 {
  font-size: 18px;
  font-weight: 600;
}

.creneau-pris {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.75);
}

.creneau-pris i {
  margin: 0;
}

